var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { align: "left", justify: "center" } },
    [
      _vm.mostrarConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarConfirmacao,
              tituloModal: _vm.tituloModal,
              textoModal: _vm.textoModal,
              btnConfirmarEmit: _vm.btnConfirmarEmit
            },
            on: {
              cancelarComando: _vm.cancelarComando,
              fecharModal: function($event) {
                _vm.mostrarConfirmacao = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mensagem,
              expression: "mensagem"
            }
          ],
          staticClass: "ma-3 white--text",
          attrs: {
            dense: "",
            dismissible: "",
            color: _vm.alertColor,
            type: _vm.alertType,
            border: "left"
          }
        },
        [_vm._v(_vm._s(_vm.mensagemRetorno))]
      ),
      _vm.dialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "400" },
                  on: { "click:outside": _vm.cancelDialogGeneric },
                  model: {
                    value: _vm.dialogConfirmationGeneric,
                    callback: function($$v) {
                      _vm.dialogConfirmationGeneric = $$v
                    },
                    expression: "dialogConfirmationGeneric"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline primary--text" },
                        [_vm._v(_vm._s(_vm.genericDialog.title))]
                      ),
                      _c("v-card-text", [
                        _vm._v(" " + _vm._s(_vm.genericDialog.text) + " ")
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                "data-cy": "confirmaCancelamento"
                              },
                              on: { click: _vm.confirmaDialogGeneric }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.genericDialog.labelConfirmBtn) + " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", text: "" },
                              on: { click: _vm.cancelDialogGeneric }
                            },
                            [_vm._v(_vm._s(_vm.genericDialog.labelCancelBtn))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "400" },
                  on: { "click:outside": _vm.negaCancelamento },
                  model: {
                    value: _vm.dialogConfirmation,
                    callback: function($$v) {
                      _vm.dialogConfirmation = $$v
                    },
                    expression: "dialogConfirmation"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline primary--text" },
                        [_vm._v(_vm._s(_vm.tipo_cancelamento.substring(2)))]
                      ),
                      _c(
                        "v-card-text",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.dialogResponse,
                              expression: "!dialogResponse"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            " Deseja realmente realizar " +
                              _vm._s(_vm.tipo_cancelamento) +
                              " do título " +
                              _vm._s(_vm.titulo.ds_nosso_numero) +
                              "? " +
                              _vm._s(_vm.dialogExtra) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dialogResponse,
                              expression: "dialogResponse"
                            }
                          ]
                        },
                        [_vm._v(_vm._s(_vm.dialogText))]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.dialogResponse,
                                  expression: "!dialogResponse"
                                }
                              ],
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                "data-cy": "confirmaCancelamento"
                              },
                              on: { click: _vm.confirmaCancelamento }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.labelConfirm ? _vm.labelConfirm : "Sim"
                                ) + " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.dialogResponse,
                                  expression: "!dialogResponse"
                                }
                              ],
                              attrs: { color: "red darken-1", text: "" },
                              on: { click: _vm.negaCancelamento }
                            },
                            [_vm._v("Não")]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.dialogResponse,
                                  expression: "dialogResponse"
                                }
                              ],
                              attrs: {
                                color: "green darken-1",
                                text: "",
                                "data-cy": "confirmaResponse"
                              },
                              on: { click: _vm.fechaCancelamento }
                            },
                            [_vm._v("Ok! ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "800px" },
                  model: {
                    value: _vm.modalInformativoPreAnuencia,
                    callback: function($$v) {
                      _vm.modalInformativoPreAnuencia = $$v
                    },
                    expression: "modalInformativoPreAnuencia"
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "ma-2 pa-2 font-weight-bold title" },
                        [_vm._v("Deseja programar o envio da anuência?")]
                      ),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " O título/dívida ainda está com status “Apontado”. Portanto, o envio da anuência será programado para ser feito automaticamente logo após a formalização do protesto, ou seja, depois da intimação do devedor (presencial, carta com AR ou por edital) e da lavratura do instrumento de protesto. "
                        )
                      ]),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " O Apresentante/Credor confirma que o devedor está ciente que: "
                        )
                      ]),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " - Como condição da negociação/pagamento da dívida enviada a protesto, caberá ao devedor pagar os emolumentos diretamente ao cartório depois que o procedimento de protesto for concluído; "
                        )
                      ]),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " - Ele(a) poderá receber uma intimação do cartório sobre o protesto; "
                        )
                      ]),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " - A anuência eletrônica está programada e será enviada ao cartório apenas após a formalização do protesto. "
                        )
                      ]),
                      _c("v-card-text", { staticClass: "ma-2 pa-2" }, [
                        _vm._v(
                          " A Recuperi irá enviar e-mail(s) ao devedor no endereço de e-mail cadastrado pelo Apresentante/Credor com orientações sobre o procedimento de protesto, a programação do envio da anuência e pagamento de emolumentos. "
                        )
                      ]),
                      _c("v-checkbox", {
                        staticClass: "ma-2 pa-2",
                        attrs: {
                          "data-cy": "anuenciaTermosCheckbox",
                          label: `O Apresentante/Credor autoriza a Recuperi a programar o envio de anuência.`
                        },
                        model: {
                          value: _vm.checkbox,
                          callback: function($$v) {
                            _vm.checkbox = $$v
                          },
                          expression: "checkbox"
                        }
                      }),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "green white--text",
                                disabled: !_vm.checkbox,
                                "data-cy": "confirmarPreAnuencia"
                              },
                              on: { click: _vm.confirmaCancelamento }
                            },
                            [_vm._v("Confirmar ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red darken-1", text: "" },
                              on: { click: _vm.negaCancelamento }
                            },
                            [_vm._v("Cancelar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-0",
                              attrs: {
                                icon: "",
                                dark: "",
                                "data-cy": "fecharModalTitulo"
                              },
                              on: { click: _vm.closeDialog }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _vm._v("Detalhes do Título ")
                          ]),
                          _vm.superUsuario
                            ? _c(
                                "div",
                                { staticClass: "py-0 pr-0 pl-4" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      content:
                                                        _vm.lenOfertasGlobais,
                                                      value:
                                                        _vm.lenOfertasGlobais,
                                                      color: "error",
                                                      overlap: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text",
                                                            style: _vm.lenOfertasGlobais
                                                              ? "background-color: #103c6b"
                                                              : "background-color: #b8c4d4",
                                                            attrs: {
                                                              dark: "",
                                                              small: "",
                                                              disabled:
                                                                _vm.ativaBotaoOferta,
                                                              loading:
                                                                _vm.ofertaGlobalLoading
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { dark: "" }
                                                          },
                                                          [_vm._v(" mdi-sale ")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2234044872
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.lenOfertasGlobais
                                                ? "Título atingido por uma ou mais Ofertas Globais ativas"
                                                : "Título não atingido por Ofertas Globais ativas"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.superUsuario
                            ? _c(
                                "div",
                                { staticClass: "py-0 pr-0 pl-4" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      content:
                                                        _vm.lenOfertasTitulo,
                                                      value:
                                                        _vm.lenOfertasTitulo,
                                                      color: "error",
                                                      overlap: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "white--text",
                                                            style: _vm.lenOfertasTitulo
                                                              ? "background-color: #103c6b"
                                                              : "background-color: #b8c4d4",
                                                            attrs: {
                                                              dark: "",
                                                              small: "",
                                                              disabled:
                                                                _vm.ativaBotaoOferta,
                                                              loading:
                                                                _vm.ofertaTituloLoading
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { dark: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-cash-multiple "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4109702401
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.lenOfertasTitulo
                                                ? "Título possui Oferta Título ativas"
                                                : "Título não possui Ofertas Título ativas"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                gap: "12px",
                                "padding-right": "32px"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "secondary text-center white--text",
                                  attrs: {
                                    loading: _vm.loadingTituloImpressao,
                                    "data-cy": "imprimirHistorico"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.historicoImpressao(_vm.titulo)
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Histórico de E-mails "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-printer")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "secondary text-center white--text",
                                  attrs: {
                                    loading: _vm.loadingTituloImpressao
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tituloImpressao(_vm.titulo)
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Dados do Título "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("mdi-printer")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c("DetalheTitulo", {
                        attrs: {
                          titulo: _vm.titulo,
                          setAtualizarTitulo: _vm.setAtualizarTitulo,
                          mostrarConfirmacao: _vm.mostrarConfirmacao,
                          habilitaCorrecao: _vm.habilitaCorrecao
                        },
                        on: {
                          habilitaDialogOferta: function($event) {
                            return _vm.habilitaDialOferta(_vm.titulo)
                          },
                          fecharModal: _vm.closeDialog,
                          showSnackbar: _vm.snackbarDetalheTitulo,
                          atualizarTitulo: _vm.atualizarTitulo
                        }
                      }),
                      _c(
                        "v-expansion-panels",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v(" $expand ")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    431335688
                                  )
                                },
                                [
                                  _c(
                                    "h2",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "grey--text mr-3" },
                                        [_vm._v("mdi-email")]
                                      ),
                                      _vm._v("Histórico de E-mails ")
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.historicoCheck,
                                          expression: "historicoCheck"
                                        }
                                      ],
                                      staticClass: "mr-3 pr-3"
                                    },
                                    [
                                      _c("p", { staticClass: "ma-3 pa-3" }, [
                                        _vm._v(
                                          " Nossa plataforma realiza envios automáticos de e-mails a todos os devedores, por regra esses devedores devem ter um e-mail devidamente cadastrado no momento do upload na plataforma. Confira abaixo os e-mails que já foram enviados a esse titulo: "
                                        )
                                      ]),
                                      _c(
                                        "v-timeline",
                                        {
                                          staticClass: "ma-3",
                                          attrs: {
                                            "align-top": "",
                                            dense:
                                              _vm.$vuetify.breakpoint.smAndDown,
                                            "data-cy": "listaEmails"
                                          }
                                        },
                                        _vm._l(_vm.historicoEmails, function(
                                          item
                                        ) {
                                          return _c(
                                            "v-timeline-item",
                                            {
                                              key: item.id,
                                              staticClass: "mb-5",
                                              attrs: {
                                                icon: "mdi-email",
                                                color: "grey"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dark: ""
                                                  }
                                                },
                                                [
                                                  item.cd_status === 220
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Solicitado Protesto "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto font-weight-black ma-3 pa-3",
                                                              attrs: {
                                                                large: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Solicitado Protesto "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 225
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                "x-large": "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Apontado "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 102 &&
                                                  item.lembrete === 1
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Protestado ( Lembrete de Protesto ) "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 102 &&
                                                  item.lembrete === 0
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Protestado "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 240
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Solicitado Cancelamento "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 241
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Enviado a Cancelamento "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 250
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Solicitado Anuência "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 251
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Enviado a Anuência "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.cd_status === 252
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary font-weight-black ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Anuência programada "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " Data do envio do e-mail: " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      item.dt_envio
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "primary ma-auto ma-3 pa-3",
                                                              attrs: {
                                                                small: "",
                                                                block: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", {}, [
                                                                _vm._v(
                                                                  "mdi-information-outline"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " E-mail Enviado com Sucesso! "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "white text--primary"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    outlined: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.visualizarEmail(
                                                                        item
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Ver Detalhes"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.historicoVazio,
                                          expression: "historicoVazio"
                                        }
                                      ],
                                      staticClass: "mr-3 pr-3"
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "ma-3 pa-3" },
                                        [
                                          _c("v-icon", {}, [
                                            _vm._v("mdi-information-outline")
                                          ]),
                                          _vm._v(
                                            " Nenhum registro encontrado para esse título "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("HistoricoTitulo", {
                        staticClass: "pa-3",
                        attrs: { historico_titulo: _vm.historicos }
                      }),
                      _vm.usuarioRecuperi &&
                      _vm.superUsuario &&
                      !_vm.usuarioPartner
                        ? _c(
                            "v-card",
                            {
                              staticClass: "pa-3 ma-3",
                              attrs: { "data-cy": "card-comentarios" }
                            },
                            [
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.habilitaComentarios,
                                      expression: "habilitaComentarios"
                                    }
                                  ],
                                  staticClass: "title"
                                },
                                [
                                  _c("v-icon", { staticClass: "ma-2" }, [
                                    _vm._v("mdi-information-outline")
                                  ]),
                                  _vm._v("Comentários do Título ")
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.habilitaComentarios,
                                      expression: "!habilitaComentarios"
                                    }
                                  ],
                                  staticClass: "title"
                                },
                                [
                                  _c("v-icon", { staticClass: "ma-2" }, [
                                    _vm._v("mdi-text-search")
                                  ]),
                                  _vm._v("Buscando Comentários ")
                                ],
                                1
                              ),
                              _c("v-progress-linear", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.habilitaComentarios,
                                    expression: "!habilitaComentarios"
                                  }
                                ],
                                attrs: {
                                  "buffer-value": "55",
                                  color: "primary",
                                  reverse: "",
                                  stream: "",
                                  value: "30"
                                }
                              }),
                              _vm._l(_vm.listaComentarios, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "v-card",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.habilitaComentarios,
                                        expression: "habilitaComentarios"
                                      }
                                    ],
                                    key: index
                                  },
                                  [
                                    _c("v-card-text", [
                                      _vm._v("Comentário criado por: "),
                                      _c("b", [
                                        _vm._v(_vm._s(item.email_user))
                                      ]),
                                      _vm._v(
                                        " (id: " +
                                          _vm._s(item.id_user) +
                                          "/" +
                                          _vm._s(item.name_user) +
                                          ") - Criado em: " +
                                          _vm._s(
                                            _vm.formatData(item.dt_criacao)
                                          )
                                      )
                                    ]),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { md: "2", lg: "2" } },
                                          [
                                            _c("v-card-text", [
                                              _c("b", [_vm._v("Comentário:")])
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "10", lg: "10" } },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                readonly: "",
                                                rows: "1",
                                                autoGrow: "",
                                                solo: ""
                                              },
                                              model: {
                                                value: item.comentario,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "comentario",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.comentario"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "v-card",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.habilitaComentarios,
                                      expression: "habilitaComentarios"
                                    }
                                  ],
                                  staticClass: "pa-3 mt-5",
                                  attrs: { color: "grey lighten-3" }
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "title" },
                                    [
                                      _c("v-icon", { staticClass: "ma-2" }, [
                                        _vm._v("mdi-plus-box")
                                      ]),
                                      _vm._v("Criar Novo comentário ")
                                    ],
                                    1
                                  ),
                                  _c("v-textarea", {
                                    attrs: {
                                      solo: "",
                                      rows: "3",
                                      label:
                                        "Informe aqui o comentário que deseja inserir no título"
                                    },
                                    model: {
                                      value: _vm.comentario,
                                      callback: function($$v) {
                                        _vm.comentario = $$v
                                      },
                                      expression: "comentario"
                                    }
                                  }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            align: "right",
                                            justify: "right"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary white--text",
                                              attrs: {
                                                loading:
                                                  _vm.habilitaLoadingComentarios
                                              },
                                              on: { click: _vm.criarComentario }
                                            },
                                            [_vm._v("Inserir comentário")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template220,
                            callback: function($$v) {
                              _vm.template220 = $$v
                            },
                            expression: "template220"
                          }
                        },
                        [
                          _c("Template220", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante,
                              saldoProtestoFormatado:
                                _vm.titulo.vl_saldo_protesto_formatado
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template220 = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template225,
                            callback: function($$v) {
                              _vm.template225 = $$v
                            },
                            expression: "template225"
                          }
                        },
                        [
                          _c("Template225", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template225 = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template102,
                            callback: function($$v) {
                              _vm.template102 = $$v
                            },
                            expression: "template102"
                          }
                        },
                        [
                          _c("Template102", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante,
                              saldoProtestoFormatado:
                                _vm.titulo.vl_saldo_protesto_formatado
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template102 = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template102Lembrete,
                            callback: function($$v) {
                              _vm.template102Lembrete = $$v
                            },
                            expression: "template102Lembrete"
                          }
                        },
                        [
                          _c("Template102Lembrete", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template102Lembrete = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template240,
                            callback: function($$v) {
                              _vm.template240 = $$v
                            },
                            expression: "template240"
                          }
                        },
                        [
                          _c("Template240", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template240 = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template250,
                            callback: function($$v) {
                              _vm.template250 = $$v
                            },
                            expression: "template250"
                          }
                        },
                        [
                          !_vm.isSaoPaulo
                            ? _c("Template250", {
                                attrs: {
                                  usuarioPartner: _vm.usuarioPartner,
                                  nomeDevedor: _vm.titulo.nm_devedor,
                                  nomeExibicaoApresentante:
                                    _vm.NomeExibicaoApresentante
                                },
                                on: {
                                  fecharModal: function($event) {
                                    _vm.template250 = $event
                                  }
                                }
                              })
                            : _c("Template250SP", {
                                attrs: {
                                  usuarioPartner: _vm.usuarioPartner,
                                  nomeDevedor: _vm.titulo.nm_devedor,
                                  nomeExibicaoApresentante:
                                    _vm.NomeExibicaoApresentante
                                },
                                on: {
                                  fecharModal: function($event) {
                                    _vm.template250 = $event
                                  }
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.template252,
                            callback: function($$v) {
                              _vm.template252 = $$v
                            },
                            expression: "template252"
                          }
                        },
                        [
                          _c("Template252", {
                            attrs: {
                              usuarioPartner: _vm.usuarioPartner,
                              nomeDevedor: _vm.titulo.nm_devedor,
                              ds_nosso_numero: _vm.titulo.ds_nosso_numero,
                              nomeExibicaoApresentante:
                                _vm.NomeExibicaoApresentante,
                              saldoProtestoFormatado:
                                _vm.titulo.vl_saldo_protesto_formatado
                            },
                            on: {
                              fecharModal: function($event) {
                                _vm.template252 = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          staticClass: "ma-3 pa-3",
                          attrs: {
                            persistent: "",
                            transition: "dialog-bottom-transition",
                            "max-width": "1000px"
                          },
                          model: {
                            value: _vm.dialogInfoDesistencia,
                            callback: function($$v) {
                              _vm.dialogInfoDesistencia = $$v
                            },
                            expression: "dialogInfoDesistencia"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-5 ma-4",
                                      attrs: { md: "7", lg: "7" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "font-weight-black" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { size: "50" }
                                            },
                                            [_vm._v("mdi-alert-circle-outline")]
                                          ),
                                          _vm._v("Atenção ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "red--text display-1 font-weight-black"
                                        },
                                        [
                                          _vm._v(
                                            " A desistência foi solicitada após o prazo legal de 3 dias da data de envio do título para protesto. "
                                          )
                                        ]
                                      ),
                                      _c("v-card-text", {}, [
                                        _vm._v(
                                          " Caso o cartório responsável não aceite o pedido de desistência (de acordo com a Lei nº 9.492/1997), a Recuperi enviará automaticamente o comando de cancelamento assim que o devedor for efetivamente protestado. "
                                        )
                                      ]),
                                      _c("v-card-text", {}, [
                                        _vm._v(
                                          " Importante: O comando de desistência gera custos de responsabilidade do apresentante. "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-5 ma-4",
                                      attrs: { md: "4", lg: "4" }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _vm._v("Saiba mais: "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "http://www.planalto.gov.br/ccivil_03/leis/l9492.htm",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " http://www.planalto.gov.br/ccivil_03/leis/l9492.htm"
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "ma-2" },
                                            [_vm._v("mdi-arrow-right")]
                                          ),
                                          _vm._v(
                                            "Em caso de dúvida, por favor entre em contato com suporte@recuperi.com.br "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3 pa-3",
                                          attrs: { color: "green white--text" },
                                          on: {
                                            click:
                                              _vm.confirmaCancelamentoDesistencia
                                          }
                                        },
                                        [_vm._v("Ok, desejo continuar")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3 pa-3",
                                          attrs: { color: "red white--text" },
                                          on: {
                                            click:
                                              _vm.fechaDialogInfoDesistencia
                                          }
                                        },
                                        [_vm._v("Fechar")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ma-3 pa-3" })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            persistent: "",
                            transition: "dialog-bottom-transition",
                            "max-width": "1100px"
                          },
                          model: {
                            value: _vm.reapresentacaoDialog,
                            callback: function($$v) {
                              _vm.reapresentacaoDialog = $$v
                            },
                            expression: "reapresentacaoDialog"
                          }
                        },
                        [
                          _c("reapresentacao-titulo", {
                            attrs: { titulo: _vm.titulo },
                            on: {
                              fecharModal: function($event) {
                                _vm.reapresentacaoDialog = false
                              },
                              reloadTable: _vm.reloadTable
                            }
                          })
                        ],
                        1
                      ),
                      _vm.habilitaCorrecao
                        ? _c(
                            "v-dialog",
                            {
                              attrs: {
                                persistent: "",
                                transition: "dialog-bottom-transition",
                                "max-width": "900px"
                              },
                              model: {
                                value: _vm.habilitaCorrecao,
                                callback: function($$v) {
                                  _vm.habilitaCorrecao = $$v
                                },
                                expression: "habilitaCorrecao"
                              }
                            },
                            [
                              _c("correcao-titulo", {
                                attrs: {
                                  detalhe_titulo: _vm.titulo,
                                  open: _vm.computedOpen
                                },
                                on: {
                                  fecharModal: _vm.desabilitaDialogCorrecao
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.habilitaOferta
                        ? _c(
                            "v-dialog",
                            {
                              attrs: {
                                transition: "dialog-bottom-transition",
                                "max-width": "1200px"
                              },
                              model: {
                                value: _vm.habilitaOferta,
                                callback: function($$v) {
                                  _vm.habilitaOferta = $$v
                                },
                                expression: "habilitaOferta"
                              }
                            },
                            [
                              _c("envia-oferta", {
                                attrs: {
                                  detalhe_titulo: _vm.titulo,
                                  ofertas: _vm.ofertas,
                                  open: _vm.computedOpen
                                },
                                on: {
                                  fechar: _vm.desabilitaDialogOferta,
                                  atualizar: function($event) {
                                    return _vm.exibirOferta(_vm.titulo)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-divider"),
                  !_vm.isUserReadonly
                    ? _c(
                        "v-card-actions",
                        {
                          staticClass: "d-block py-4",
                          staticStyle: { height: "auto" },
                          attrs: { "data-cy": "consultaTituloActions" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                padding: "12px 56px 8px 30px"
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  (_vm.superUsuario &&
                                    _vm.usuarioRecuperi &&
                                    _vm.titulo.cd_status === 102) ||
                                  (_vm.superUsuario &&
                                    _vm.usuarioRecuperi &&
                                    _vm.titulo.cd_status === 144)
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            dense: "",
                                            color: "green white--text",
                                            disabled: _vm.ativaBotaoOferta
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.exibirOferta(
                                                _vm.titulo
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" Ofertas "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-plus-circle")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.superUsuario && _vm.usuarioRecuperi
                                    ? _c(
                                        "v-btn",
                                        {
                                          class:
                                            _vm.superUsuario &&
                                            _vm.usuarioRecuperi
                                              ? "ml-3"
                                              : "",
                                          attrs: {
                                            dense: "",
                                            color: "green white--text",
                                            disabled:
                                              _vm.desabilitaBotaoFila ||
                                              _vm.isUserReadonly
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.recolocarEmFila(
                                                _vm.titulo
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.recolocarEmFilaButton,
                                            callback: function($$v) {
                                              _vm.recolocarEmFilaButton = $$v
                                            },
                                            expression: "recolocarEmFilaButton"
                                          }
                                        },
                                        [
                                          _vm._v(" Reiniciar Envio "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-restart")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.ultimoFiltro?.tem_irr_nao_reapresentado &&
                                  !_vm.usuarioLite
                                    ? _c(
                                        "v-btn",
                                        {
                                          class:
                                            _vm.superUsuario &&
                                            _vm.usuarioRecuperi
                                              ? "ml-3"
                                              : "",
                                          attrs: {
                                            "data-cy":
                                              "btn-reapresentar-titulo",
                                            dense: "",
                                            color: "green white--text"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.reapresentacaoDialog = true
                                            }
                                          },
                                          model: {
                                            value: _vm.recolocarEmFilaButton,
                                            callback: function($$v) {
                                              _vm.recolocarEmFilaButton = $$v
                                            },
                                            expression: "recolocarEmFilaButton"
                                          }
                                        },
                                        [
                                          _vm._v(" Reapresentar "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-restart")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.superUsuario || _vm.usuarioAdmin) &&
                                  _vm.verificarCancelarComandoTitulo(_vm.titulo)
                                    ? _c(
                                        "v-btn",
                                        {
                                          class:
                                            _vm.superUsuario &&
                                            _vm.usuarioRecuperi
                                              ? "ml-3"
                                              : "",
                                          attrs: {
                                            dense: "",
                                            color: "red white--text",
                                            disabled: _vm.isUserReadonly,
                                            "data-cy": "cancelarComando"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.modalCancelarComando()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" Cancelar Comando "),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("cancel")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                !_vm.usuarioLite &&
                                                _vm.titulo.ds_revisao !== "AP"
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              disabled:
                                                                _vm.computedPermitirCorrecao ||
                                                                _vm.isUserReadonly,
                                                              dense: "",
                                                              color:
                                                                "teal white--text",
                                                              "data-cy":
                                                                "correcaoBtn"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.habilitaCorrecao = !_vm.habilitaCorrecao
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(" Correção "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("build")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        430895044
                                      )
                                    },
                                    [_c("span", [_vm._v("Realizar Correção")])]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        top: "",
                                        width: "300",
                                        "max-width": "350"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          dense: "",
                                                          color:
                                                            "success darken-1",
                                                          disabled:
                                                            _vm.computedPermitirAnuencia ||
                                                            _vm.isUserReadonly,
                                                          loading:
                                                            _vm.loadingCancelamento,
                                                          "data-cy":
                                                            "solicitarAnuencia"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.solicitaAnuencia
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Anuência "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: { small: "" }
                                                      },
                                                      [_vm._v("check")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4191795376
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " Programar pedido de Anuência - "
                                        )
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " Informação Importante: Apenas os valores protestados (ou outros valores, conforme renegociado com o Devedor) foram pagos pelo Devedor ao Apresentante – Caberá ao Devedor realizar o pagamento dos emolumentos diretamente aos cartórios. "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.usuarioLite,
                                                            expression:
                                                              "!usuarioLite"
                                                          }
                                                        ],
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          dense: "",
                                                          color:
                                                            "orange darken-3 white--text",
                                                          "data-cy":
                                                            "solicitarCancelamento",
                                                          disabled:
                                                            _vm.computedPermitirCancelamento ||
                                                            _vm.isUserReadonly,
                                                          loading:
                                                            _vm.loadingCancelamento
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.solicitaCancelamento
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Cancelamento "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: { small: "" }
                                                      },
                                                      [_vm._v("cancel")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3497140717
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Solicitar pedido de Cancelamento do Protesto"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.usuarioLite,
                                                            expression:
                                                              "!usuarioLite"
                                                          }
                                                        ],
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          dense: "",
                                                          color:
                                                            _vm.colorDesistencia,
                                                          disabled:
                                                            _vm.computedPermitirDesistencia ||
                                                            _vm.isUserReadonly,
                                                          loading:
                                                            _vm.loadingCancelamento
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.solicitaDesistencia
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(" Desistência "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: { small: "" }
                                                      },
                                                      [_vm._v("stop")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2919796359
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Solicitar pedido de Desistência do Protesto"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.superUsuario && _vm.usuarioRecuperi
                            ? _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-divider"),
                                  _c("strong", { staticClass: "px-3" }, [
                                    _vm._v(" Ações de usuário Recuperi")
                                  ]),
                                  _c("v-divider")
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.superUsuario && _vm.usuarioRecuperi
                            ? _c(
                                "v-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "end",
                                    padding: "8px 56px 8px 42px"
                                  },
                                  attrs: { "data-cy": "superAdminSession" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _vm.statusParaIrregularizarTitulo.includes(
                                                  _vm.titulo.cd_status
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              dense: "",
                                                              color:
                                                                _vm.colorDesistencia
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.solicitarInvalidacaoComarca(
                                                                  "K1"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(" 105-K1 "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("stop")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4248094967
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Irregularizar o título devido a comarca estar inválida"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function({ on, attrs }) {
                                              return [
                                                _vm.statusParaIrregularizarTituloK3.includes(
                                                  _vm.titulo.cd_status
                                                )
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              dense: "",
                                                              color:
                                                                _vm.colorDesistencia
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.solicitarInvalidacaoComarca(
                                                                  "K3"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(" 105-K3 "),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("stop")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        104690863
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Irregularizar o título devido a comarca estar inválida"
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm.statusFinalizadores.includes(
                                    _vm.titulo.cd_status
                                  )
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on, attrs }) {
                                                  return [
                                                    _vm.usuarioRecuperi
                                                      ? _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                class: _vm.usuarioRecuperi
                                                                  ? "ml-3"
                                                                  : "",
                                                                attrs: {
                                                                  dense: "",
                                                                  color:
                                                                    "red white--text"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.desativarMailing()
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.titulo
                                                                    .st_email_callback ===
                                                                    1
                                                                    ? "Desativar Mailing"
                                                                    : "Ativar Mailing"
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [_vm._v("cancel")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            620950798
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Desativar Mailing para este título, outros títulos para este e-mail não é alterado"
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.statusFinalizadores.includes(
                                    _vm.titulo.cd_status
                                  )
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            class: _vm.usuarioRecuperi
                                                              ? "ml-3"
                                                              : "",
                                                            attrs: {
                                                              dense: "",
                                                              color:
                                                                "blue darken-3 white--text"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openModalTrocarContatoDevedor()
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Alterar Contatos Devedor "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2699796902
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Alterar contatos de e-mail e telefone de devedor"
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalTrocarContatoDevedor,
            callback: function($$v) {
              _vm.modalTrocarContatoDevedor = $$v
            },
            expression: "modalTrocarContatoDevedor"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text pl-0" },
                    [_vm._v("Alterar contatos do devedor")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "E-mail do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.novosContatosdevedor.email,
                              callback: function($$v) {
                                _vm.$set(_vm.novosContatosdevedor, "email", $$v)
                              },
                              expression: "novosContatosdevedor.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "(##) #####-####",
                                expression: "'(##) #####-####'"
                              }
                            ],
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Telefone do devedor",
                              required: ""
                            },
                            model: {
                              value: _vm.novosContatosdevedor.celular,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.novosContatosdevedor,
                                  "celular",
                                  $$v
                                )
                              },
                              expression: "novosContatosdevedor.celular"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { dense: "", color: "red white--text", text: "" },
                      on: {
                        click: function($event) {
                          _vm.modalTrocarContatoDevedor = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dense: "", color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.trocarContatoDevedor()
                        }
                      }
                    },
                    [_vm._v("Salvar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            transition: "dialog-bottom-transition",
            width: "450px"
          },
          model: {
            value: _vm.dialogLoading,
            callback: function($$v) {
              _vm.dialogLoading = $$v
            },
            expression: "dialogLoading"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center", attrs: { width: "420px" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-progress-circular",
                    {
                      staticClass: "ma-12",
                      attrs: {
                        indeterminate: "",
                        size: "150",
                        color: "primary"
                      }
                    },
                    [_vm._v("Carregando... ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.mostraConfirmacaoRelatorioEmail,
            callback: function($$v) {
              _vm.mostraConfirmacaoRelatorioEmail = $$v
            },
            expression: "mostraConfirmacaoRelatorioEmail"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostraConfirmacaoRelatorioEmail,
                          expression: "mostraConfirmacaoRelatorioEmail"
                        }
                      ],
                      attrs: {
                        "data-cy": "btnSubmitReportConfirmation",
                        color: "green darken-1",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.mostraConfirmacaoRelatorioEmail = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.mostraConfirmacaoRelatorioEmailDetalhado,
            callback: function($$v) {
              _vm.mostraConfirmacaoRelatorioEmailDetalhado = $$v
            },
            expression: "mostraConfirmacaoRelatorioEmailDetalhado"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostraConfirmacaoRelatorioEmailDetalhado,
                          expression: "mostraConfirmacaoRelatorioEmailDetalhado"
                        }
                      ],
                      attrs: {
                        color: "green darken-1",
                        "data-cy": "btnSubmitReportConfirmationCsv",
                        text: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.mostraConfirmacaoRelatorioEmailDetalhado = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.mostraConfirmacaoPdf,
            callback: function($$v) {
              _vm.mostraConfirmacaoPdf = $$v
            },
            expression: "mostraConfirmacaoPdf"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c("v-spacer"),
                  _c("v-card-subtitle", [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostraConfirmacaoPdf,
                          expression: "mostraConfirmacaoPdf"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.mostraConfirmacaoPdf = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modelRelatorioXlsEmail,
            callback: function($$v) {
              _vm.modelRelatorioXlsEmail = $$v
            },
            expression: "modelRelatorioXlsEmail"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-form",
                    {
                      ref: "formEmail",
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.checkformSubmit()
                        }
                      },
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          rules: _vm.emailRules,
                          required: "",
                          "data-cy": "emailFieldforReport"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "btnSubmitReport"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarEmailSQS()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modelRelatorioDetalhado,
            callback: function($$v) {
              _vm.modelRelatorioDetalhado = $$v
            },
            expression: "modelRelatorioDetalhado"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-form",
                    {
                      ref: "formEmail",
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          rules: _vm.emailRules,
                          required: "",
                          "data-cy": "emailFieldforReportCsv"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "submitReportCsv"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarEmailDetalhado()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modelListaPdfSimples,
            callback: function($$v) {
              _vm.modelListaPdfSimples = $$v
            },
            expression: "modelListaPdfSimples"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-form",
                    {
                      ref: "formEmail",
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          rules: _vm.emailRules,
                          required: "",
                          "data-cy": "emailFieldforReportPDF"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "submitReportPDF"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarEmailPdfSimples()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar "),
                      _c("v-icon", { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-card",
            { attrs: { align: "left", justify: "left" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-4",
                      attrs: { "x-small": "", fab: "", icon: "" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "primary--text lighten-2 pa-2" },
                        [_vm._v(" search ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" Títulos > Consulta de Títulos ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "my-2",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.botaoFiltrar.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-3 mt-3 mb-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row",
                      attrs: { cols: "5", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-textarea", {
                        staticClass: "mt-5",
                        style: {
                          maxHeight: "15rem",
                          overflowY: "auto"
                        },
                        attrs: {
                          "auto-grow": "",
                          solo: "",
                          small: "",
                          rows: "1",
                          label: "Nosso número",
                          hint: "Informe o nosso número",
                          placeholder: "Ex.:477977",
                          "data-cy": "filtroNossoNumero"
                        },
                        model: {
                          value: _vm.filtros.ds_nosso_numero,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                          },
                          expression: "filtros.ds_nosso_numero"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-auto ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color:
                                                  "grey\n                                                                                            "
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Informe o Número do Título")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row align-center",
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                            expression:
                              "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                          }
                        ],
                        staticClass: "mt-5",
                        attrs: {
                          solo: "",
                          small: "",
                          label: "CPF/CNPJ",
                          hint: "Informe o Número do CPF/CNPJ",
                          placeholder: "Ex.:000.000.000-00",
                          clearable: "",
                          "data-cy": "filtroCpfCnpj"
                        },
                        model: {
                          value: _vm.filtros.nu_identificacao_devedor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.filtros,
                              "nu_identificacao_devedor",
                              $$v
                            )
                          },
                          expression: "filtros.nu_identificacao_devedor"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-auto ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "grey--text" },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Informe o CPF/CNPJ, ou apenas seus números iniciais"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row",
                      attrs: { cols: "5", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-5",
                        attrs: {
                          solo: "",
                          small: "",
                          label: "Nome",
                          hint: "Informe o nome para efetuar a busca",
                          placeholder: "Ex.: José",
                          clearable: "",
                          "data-cy": "nomeDevedor"
                        },
                        model: {
                          value: _vm.filtros.nm_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "nm_devedor", $$v)
                          },
                          expression: "filtros.nm_devedor"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-auto ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { color: "grey" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Informe o Nome que deseja encontrar, por exemplo: Ricardo"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row",
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mt-5",
                        attrs: {
                          loading: _vm.loadingInicial,
                          solo: "",
                          multiple: "",
                          small: "",
                          items: _vm.listaSituacoes,
                          label: "Status",
                          "item-text": "nm_status",
                          "item-value": "cd_status",
                          clearable: "",
                          counter: "6",
                          hint: "Selecione até 6 status para filtrar",
                          "persistent-hint": "",
                          "data-cy": "statusTitulo"
                        },
                        on: { input: _vm.verificaStatus },
                        model: {
                          value: _vm.filtros.cd_status,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_status", $$v)
                          },
                          expression: "filtros.cd_status"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-auto ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { color: "grey" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v("Selecione um tipo de status desejado")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-3 mt-1 mb-0", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row",
                      attrs: { cols: "5", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      small: "",
                                      label: "Data de cadastro",
                                      hint: "Data de cadastro: inícial e final",
                                      placeholder: "Selecione no botão ao lado",
                                      clearable: ""
                                    },
                                    on: { change: _vm.limparDataCadastro },
                                    model: {
                                      value:
                                        _vm.computedDateCadastroFormattedRange,
                                      callback: function($$v) {
                                        _vm.computedDateCadastroFormattedRange = $$v
                                      },
                                      expression:
                                        "computedDateCadastroFormattedRange"
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "grey--text mt-3 ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c("v-icon", _vm._g({}, on), [
                                        _vm._v(" mdi-calendar ")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menuDataCadastroRange,
                            callback: function($$v) {
                              _vm.menuDataCadastroRange = $$v
                            },
                            expression: "menuDataCadastroRange"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              range: "",
                              color: "primary",
                              locale: "pt-br"
                            },
                            on: {
                              input: function($event) {
                                return _vm.validarSelecaoData(
                                  $event,
                                  "menuDataCadastroRange"
                                )
                              }
                            },
                            model: {
                              value: _vm.filtros.dt_cadastro_range,
                              callback: function($$v) {
                                _vm.$set(_vm.filtros, "dt_cadastro_range", $$v)
                              },
                              expression: "filtros.dt_cadastro_range"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-row",
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "max-width": "290px",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      label: "Data de movimentação",
                                      placeholder: "Selecione no botão ao lado",
                                      hint: "Data Movimento: Inicial e Final",
                                      clearable: ""
                                    },
                                    on: { change: _vm.limparDataMovimento },
                                    model: {
                                      value:
                                        _vm.computedDateMovimentoFormattedRange,
                                      callback: function($$v) {
                                        _vm.computedDateMovimentoFormattedRange = $$v
                                      },
                                      expression:
                                        "\n                                    computedDateMovimentoFormattedRange\n                                "
                                    }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "grey--text mt-3 ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0",
                                        "data-cy": "dataMovimentoFilter"
                                      }
                                    },
                                    [
                                      _c("v-icon", _vm._g({}, on), [
                                        _vm._v(" mdi-calendar ")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.menuDataMovimentoRange,
                            callback: function($$v) {
                              _vm.menuDataMovimentoRange = $$v
                            },
                            expression: "menuDataMovimentoRange"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              range: "",
                              color: "primary",
                              locale: "pt-br"
                            },
                            on: {
                              input: function($event) {
                                return _vm.validarSelecaoData(
                                  $event,
                                  "menuDataMovimentoRange"
                                )
                              }
                            },
                            model: {
                              value: _vm.filtros.dt_arquivo_movimento_range,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.filtros,
                                  "dt_arquivo_movimento_range",
                                  $$v
                                )
                              },
                              expression: "filtros.dt_arquivo_movimento_range"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-3 d-flex justify-start align-center",
                      attrs: { cols: "5", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          solo: "",
                          small: "",
                          items: _vm.listaUfs,
                          label: "UF",
                          clearable: "",
                          "return-object": true
                        },
                        model: {
                          value: _vm.filtros.cd_uf_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_uf_devedor", $$v)
                          },
                          expression: "filtros.cd_uf_devedor"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-5 ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color:
                                                  "grey\n                                                            "
                                              }
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [_c("span", [_vm._v("Selecione uma UF")])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-3 d-flex justify-start align-center",
                      attrs: { cols: "4", xs: "2", sm: "2", md: "3", lg: "3" }
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          solo: "",
                          items: _vm.wallets,
                          "search-input": _vm.searchCarteira,
                          label: "Buscar carteira"
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.searchCarteira = $event
                          },
                          "update:search-input": [
                            function($event) {
                              _vm.searchCarteira = $event
                            },
                            _vm.updateSearch
                          ]
                        },
                        model: {
                          value: _vm.filtros.cd_carteira,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "cd_carteira", $$v)
                          },
                          expression: "filtros.cd_carteira"
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-5 ml-2",
                                      attrs: {
                                        fab: "",
                                        "x-small": "",
                                        elevation: "0"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { color: "grey" } },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Informe o nome da carteira que deseja encontrar. No mínimo, três caracteres. por exemplo: ABC"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-3 mt-0 mb-3", attrs: { align: "center" } },
                [
                  !_vm.isUsuarioAgrupador
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-3 d-flex justify-start align-center",
                          attrs: {
                            cols: "5",
                            xs: "2",
                            sm: "2",
                            md: "3",
                            lg: "3"
                          }
                        },
                        [
                          !_vm.usuarioLite && !_vm.usuarioLiteRO
                            ? _c("v-select", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  disabled:
                                    _vm.loadingAgrupador ||
                                    _vm.usuarioAgrupador != null,
                                  loading: _vm.loadingAgrupador,
                                  items: _vm.agrupadores,
                                  label: "Agrupador",
                                  "item-text": "nm_agrupador",
                                  "item-value": "ds_agrupador",
                                  "data-cy": "agrupador"
                                },
                                model: {
                                  value: _vm.filtros.ds_agrupador,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtros, "ds_agrupador", $$v)
                                  },
                                  expression: "filtros.ds_agrupador"
                                }
                              })
                            : _vm._e(),
                          !_vm.usuarioLite && !_vm.usuarioLiteRO
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mb-5 ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3910429653
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Selecione o agrupador que deseja filtrar"
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-3 mb-4 d-flex align-center",
                      attrs: { cols: "3", xs: "10", sm: "10", md: "9", lg: "9" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pa-3 d-flex align-center",
                          staticStyle: {
                            gap: "24px",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          !_vm.usuarioLite
                            ? _c(
                                "div",
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-n1 d-flex justify-center",
                                    attrs: {
                                      "hide-details": "",
                                      small: "",
                                      color: "primary",
                                      label: "Revisão"
                                    },
                                    model: {
                                      value: _vm.revisao,
                                      callback: function($$v) {
                                        _vm.revisao = $$v
                                      },
                                      expression: "revisao"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            [
                              _c("v-switch", {
                                staticClass: "mt-n1 d-flex justify-center",
                                attrs: {
                                  "hide-details": "",
                                  small: "",
                                  label: "Não mostrar Irregulares"
                                },
                                model: {
                                  value: _vm.irregulares,
                                  callback: function($$v) {
                                    _vm.irregulares = $$v
                                  },
                                  expression: "irregulares"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("v-switch", {
                                staticClass: "mt-n1 d-flex justify-center",
                                attrs: {
                                  "hide-details": "",
                                  small: "",
                                  label: "Com Instrumento de Protesto"
                                },
                                model: {
                                  value: _vm.comInstrumento,
                                  callback: function($$v) {
                                    _vm.comInstrumento = $$v
                                  },
                                  expression: "comInstrumento"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-3 my-0", attrs: { align: "center" } },
                [
                  !_vm.isUsuarioAgrupador
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pa-3 d-flex justify-start align-center",
                          attrs: {
                            cols: "5",
                            xs: "2",
                            sm: "2",
                            md: "3",
                            lg: "3"
                          }
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              solo: "",
                              dense: "",
                              disabled: _vm.loadingIrregularidades,
                              loading: _vm.loadingIrregularidades,
                              items: _vm.listaIrregularidades,
                              label: "Irregularidade",
                              "item-text": "nm_irregularidade",
                              "item-value": "cd_irregularidade",
                              "data-cy": "irregularidadeApresentante"
                            },
                            model: {
                              value: _vm.filtros.cd_irregularidade,
                              callback: function($$v) {
                                _vm.$set(_vm.filtros, "cd_irregularidade", $$v)
                              },
                              expression: "filtros.cd_irregularidade"
                            }
                          }),
                          !_vm.filtros.cd_irregularidade
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mb-5 ml-2",
                                                attrs: {
                                                  fab: "",
                                                  "x-small": "",
                                                  elevation: "0"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3910429653
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Selecione a Irregularidade que deseja filtrar"
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-3 mb-4 d-flex align-center",
                      attrs: { cols: "3", xs: "10", sm: "10", md: "9", lg: "9" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("v-switch", {
                            staticClass: "mt-n1 d-flex justify-center",
                            attrs: {
                              "data-cy": "switchIrregularesNaoReapresentados",
                              "hide-details": "",
                              small: "",
                              label: "Irregulares não reapresentados"
                            },
                            model: {
                              value: _vm.irregularesNaoReapresentados,
                              callback: function($$v) {
                                _vm.irregularesNaoReapresentados = $$v
                              },
                              expression: "irregularesNaoReapresentados"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-3 mt-0 mb-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-3 pt-0 pb-3",
                      attrs: {
                        cols: "7",
                        xs: "7",
                        sm: "7",
                        md: "7",
                        lg: "7",
                        align: "left"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold text-h6",
                          staticStyle: { color: "#6c6c6c" }
                        },
                        [_vm._v(" Data de atualização do Título ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-normal",
                          staticStyle: { color: "#6c6c6c" }
                        },
                        [
                          _vm._v(
                            " Pesquise por títulos atualizados dentro do prazo (em dias) selecionado. "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-row justify-end align-end px-3 pt-0 pb-3",
                      attrs: {
                        cols: "5",
                        xs: "5",
                        sm: "5",
                        md: "5",
                        lg: "5",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setDataMovimentoFiltro(1)
                            }
                          }
                        },
                        [_vm._v(" Até 1D ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setDataMovimentoFiltro(7)
                            }
                          }
                        },
                        [_vm._v(" Até 7D ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setDataMovimentoFiltro(30)
                            }
                          }
                        },
                        [_vm._v(" Até 30D ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-1 mr-0 ml-1",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setDataMovimentoFiltro(90)
                            }
                          }
                        },
                        [_vm._v(" Até 90D ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-3 mt-2 mb-0 pb-1 justify-space-between" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column justify-start",
                      attrs: { cols: "4", xs: "4", sm: "4", md: "8", lg: "8" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-start" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                medium: "",
                                color: "primary",
                                "data-cy": "exportarPdf",
                                disabled: _vm.exportActive,
                                loading: _vm.loadingExport
                              },
                              on: {
                                click: function($event) {
                                  return _vm.relatorioPdfSimples()
                                }
                              }
                            },
                            [
                              _vm._v(" PDF"),
                              _c("v-icon", [_vm._v("mdi-file-pdf-box")])
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "Exportar arquivo PDF Simples (lista) com os resultados"
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3 text-start" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                medium: "",
                                color: "primary",
                                "data-cy": "exportarXls",
                                disabled: _vm.exportActive,
                                loading: _vm.loadingExport
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exportarXls()
                                }
                              }
                            },
                            [
                              _vm._v(" XLS"),
                              _c("v-icon", [_vm._v("mdi-microsoft-excel")])
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "Exportar arquivo em formato XLS (lista) com os resultados. Este arquivo pode ser usado para o upload de títulos. " +
                                _vm._s(
                                  _vm.totalTitulos > 100000 ? "via email" : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-3 text-start" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                medium: "",
                                color: "primary",
                                disabled: _vm.exportActive,
                                loading: _vm.loadingExport,
                                "data-cy": "btnExportarCsv"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.relatorioDetalhes()
                                }
                              }
                            },
                            [
                              _vm._v(" CSV"),
                              _c("v-icon", {}, [_vm._v("mdi-file-table")])
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "Exportar relatório Detalhado em formato CSV " +
                                _vm._s(
                                  _vm.totalTitulos > 100000 ? "via email" : ""
                                )
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column justify-end",
                      attrs: {
                        cols: "6",
                        xs: "6",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            block: "",
                            color: "primary",
                            loading: _vm.loadingFiltros,
                            "data-cy": "filtrarTitulos"
                          },
                          on: { click: _vm.botaoFiltrar }
                        },
                        [
                          _vm._v(" Filtrar "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: { block: "", loading: _vm.loadingFiltros },
                          on: { click: _vm.limparFiltros }
                        },
                        [
                          _vm._v(" Limpar Filtros "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("mdi-filter-remove-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.consultaSnackbarDialog
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.consultaSnackbarDialog,
                  corSnackbar: _vm.consultaSnackbarColor,
                  mensagemSnackbar: _vm.mensagemRetornoconsultaSnackbar
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.consultaSnackbarDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("v-data-table", {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.titulos,
              "calculate-widths": "",
              options: _vm.options,
              "server-items-length": _vm.totalTitulos,
              loading: _vm.loading,
              "footer-props": {
                itemsPerPageOptions: [10, 50, 100, 500],
                itemsPerPageText: "Itens por página:"
              },
              "loading-text": "Pesquisando títulos...",
              "no-data-text":
                "Nenhum resultado encontrado... Faça uma pesquisa",
              "no-results-text": "Nenhum título encontrado..."
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.id_titulo`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(" " + _vm._s(item.id_titulo))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.id_titulo))
                          ])
                    ]
                  }
                },
                {
                  key: `item.ds_nosso_numero`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.ds_nosso_numero))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.ds_nosso_numero) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_cadastro`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.dt_cadastro))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.dt_cadastro))
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_vencimento`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.formatDataVencimento(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.formatDataVencimento(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.nu_identificacao_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.formatDocumento(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.nm_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.nm_devedor))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.nm_devedor))
                          ])
                    ]
                  }
                },
                {
                  key: `item.vl_saldo_protesto`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.vl_saldo_protesto))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.vl_saldo_protesto))
                          ])
                    ]
                  }
                },
                {
                  key: `item.cd_status`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.getDescSituacao(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_arquivo_movimento`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.dt_arquivo_movimento
                                    ? _vm.convertData(item.dt_arquivo_movimento)
                                    : item.dt_atualizacao
                                    ? item.dt_atualizacao
                                    : "--"
                                )
                              )
                            ]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(
                              _vm._s(
                                item.dt_arquivo_movimento
                                  ? _vm.convertData(item.dt_arquivo_movimento)
                                  : item.dt_atualizacao
                                  ? item.dt_atualizacao
                                  : "--"
                              )
                            )
                          ])
                    ]
                  }
                },
                {
                  key: `item.cd_uf_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != "" && item.cd_status == 99999
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.cd_uf_devedor))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.cd_uf_devedor))
                          ])
                    ]
                  }
                },
                {
                  key: `item.id_anexo_instrumento`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "span",
                        { style: { color: "black" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "white--text",
                                                style: _vm.retornarValorInstrumentoProtesto(
                                                  item,
                                                  "style"
                                                ),
                                                attrs: {
                                                  fab: "",
                                                  dark: "",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.verificarFuncaoInstrumentos(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.retornarValorInstrumentoProtesto(
                                                        item,
                                                        "icon"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.retornarValorInstrumentoProtesto(
                                        item,
                                        "tooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: `item.oferta`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-icon",
                        { style: { color: item.oferta > 0 ? "green" : "red" } },
                        [_vm._v(" " + _vm._s(_vm.getBolean(item.oferta)))]
                      )
                    ]
                  }
                },
                {
                  key: `item.cd_comprador`,
                  fn: function({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.cd_comprador === null
                              ? ""
                              : `Original: ${item.cd_apresentante}`
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _vm.isVersionBeta
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2 grey white--text",
                              style: { color: "primary" },
                              attrs: {
                                elevation: "1",
                                width: "100%",
                                "full-width": "",
                                small: "",
                                "data-cy": `editarTitulo${props.index}`
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "detalhe-titulo",
                                    query: {
                                      id_titulo: props.item.id_titulo,
                                      cd_apresentante: _vm.cd_apresentante
                                    }
                                  })
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  style: { color: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "detalhe-titulo",
                                        params: {
                                          id_titulo: props.item.id_titulo,
                                          cd_apresentante: _vm.cd_apresentante
                                        }
                                      })
                                    }
                                  }
                                },
                                [_vm._v("edit ")]
                              ),
                              _vm._v(" Detalhes ")
                            ],
                            1
                          )
                        : props.item.ds_revisao != "" &&
                          props.item.cd_status == 99999
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. ",
                                elevation: "1",
                                width: "100%",
                                "data-cy": `editarTitulo${props.index}`
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  style: { color: "red" },
                                  attrs: {
                                    title: "Este título precisa ser revisado. ",
                                    medium: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.exibirDetalhe(props.item)
                                    }
                                  }
                                },
                                [_vm._v("edit")]
                              ),
                              _vm._v(" Revisar ")
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "mr-2 grey white--text",
                              style: { color: "primary" },
                              attrs: {
                                elevation: "1",
                                width: "100%",
                                "full-width": "",
                                small: "",
                                "data-cy": `editarTitulo${props.index}`
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  style: { color: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.exibirDetalhe(props.item)
                                    }
                                  }
                                },
                                [_vm._v("edit ")]
                              ),
                              _vm._v(" Detalhes ")
                            ],
                            1
                          )
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm.snackbarDialog
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbarDialog,
                  timeoutSnackbar: _vm.snackbarTimeout,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemRetornoSnackbar
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbarDialog = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                transition: "dialog-bottom-transition",
                "max-width": "1024px"
              },
              model: {
                value: _vm.showEmailDialog,
                callback: function($$v) {
                  _vm.showEmailDialog = $$v
                },
                expression: "showEmailDialog"
              }
            },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.emailContent) } })]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }